import { createInertiaApp } from '@inertiajs/react';
import { createRoot } from 'react-dom/client';

import fetchTranslations from './shared/fetchTranslations';
import initSentry from './shared/initSentry';
import Root from './tenant-dashboard/Root';
import PageProps from './tenant-dashboard/types/inertia/PageProps';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string | undefined;
const SENTRY_TRACES_SAMPLE_RATE = import.meta.env
    .VITE_SENTRY_TRACES_SAMPLE_RATE as string | undefined;
const SIMPLE_LOCALIZE_PROJECT_TOKEN = import.meta.env
    .VITE_SIMPLE_LOCALIZE_DASHBOARD_PROJECT_TOKEN as string | undefined;
const SIMPLE_LOCALIZE_ENVIRONMENT = import.meta.env
    .VITE_SIMPLE_LOCALIZE_ENVIRONMENT as string | undefined;

const pages = import.meta.glob('./tenant-dashboard/pages/{*.tsx,*/*.tsx}');

if (SENTRY_DSN) {
    initSentry(
        SENTRY_DSN,
        SENTRY_TRACES_SAMPLE_RATE ? parseFloat(SENTRY_TRACES_SAMPLE_RATE) : 0.1
    );
}

createInertiaApp<PageProps>({
    progress: {
        delay: 250,
        color: '#12C19B',
        includeCSS: true,
        showSpinner: false,
    },
    title: (title) => `${title} | Let’s Book`,
    resolve: (name) => {
        /**
         * There's a bug in the current version of Inertia that causes the
         * `resolve` function to be called with `undefined` as the `name`
         * argument. The 'Refresh' page is a workaround for that bug.
         */
        const pageComponent =
            pages[`./tenant-dashboard/pages/${name ?? 'Refresh'}.tsx`];

        if (!pageComponent) {
            throw new Error(`Page component "${name}" not found.`);
        }

        return pageComponent();
    },

    setup: ({ el, App, props }) => {
        const localization = props.initialPage.props.localization;

        fetchTranslations({
            projectToken: SIMPLE_LOCALIZE_PROJECT_TOKEN ?? 'unknown',
            environment: SIMPLE_LOCALIZE_ENVIRONMENT ?? 'latest',
            localeCode: localization.currentLocale.code,
            fallbackUrl: localization.sourcesFallbackUrl,
        }).then((translations) => {
            createRoot(el).render(
                <Root App={App} props={props} translations={translations} />
            );
        });
    },
});
