import axios from 'axios';

const logError = (error: any) => {
    const prefix = '[LetsBook] Loading translations failed';

    if (axios.isAxiosError(error)) {
        console.log(
            `${prefix}: ${error.message}. Retrying with fallback URL...`
        );
    } else {
        console.log(
            `${prefix} due to an unknown error. Retrying with fallback URL...`
        );
    }
};

type Options = {
    localeCode: string;
    projectToken: string;
    environment: string; // 'latest' | 'production';
    fallbackUrl: string;
};

const fetchTranslations = async ({
    localeCode,
    projectToken,
    environment,
    fallbackUrl,
}: Options) => {
    const url = `https://cdn.simplelocalize.io/${projectToken}/_${environment}/${localeCode}`;

    try {
        const response = await axios.get(url, { timeout: 5000 });

        return response.data;
    } catch (error) {
        logError(error);

        try {
            const fallbackResponse = await axios.get(fallbackUrl);
            return fallbackResponse.data;
        } catch (fallbackError) {
            throw new Error(
                `Cannot load translations for locale ${localeCode} from ${url} and fallback URL ${fallbackUrl}.`
            );
        }
    }
};

export default fetchTranslations;
